import { useState } from "react";
import { encondedDefaultImage, imagePath } from "../utils";
import Image, { ImageLoader } from "next/image";

type Props = {
  alt: string;
  src: string;
  width: number;
  height: number;
  className?: string;
};

export const NiloImage = ({
  alt,
  className,
  height,
  src,
  width,
  ...props
}: Props) => {
  const defaultImage = "/img/default_image.svg";

  const loaderPath: ImageLoader = ({ quality = 75, src, width }) => {
    const imgReq = JSON.stringify({
      key: src,
      edits: {
        resize: {
          width
        },
        jpeg: {
          quality
        },
        png: {
          quality
        }
      }
    });
    const encodedObject = btoa(imgReq);
    return `${imagePath}/${encodedObject}`;
  };

  const [imageSrc, setImageSrc] = useState(src || defaultImage);
  const [error, setError] = useState(false);

  const handleError = () => {
    setError(true);
    setImageSrc(defaultImage);
  };

  return (
    <div style={{ width, height }} className="relative">
      <Image
        alt={alt}
        className={`h-full w-full rounded-xl object-contain ${className}`}
        height={height}
        loading="lazy"
        width={width}
        placeholder="blur"
        blurDataURL={encondedDefaultImage}
        onError={handleError}
        loader={error ? undefined : loaderPath}
        src={imageSrc}
        {...props}
      />
    </div>
  );
};
